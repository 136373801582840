export const preventExtraChars = (evt:  React.KeyboardEvent<HTMLInputElement>) => (evt.key === 'e' || evt.key === '.' || evt.key === ',' || evt.key === '-') && evt.preventDefault();

export function formatNumber(value: number) {
    let strNumber = value.toString();
    let formattedNumber = '';

    while (strNumber.length > 3) {
        formattedNumber = ' ' + strNumber.slice(-3) + formattedNumber;
        strNumber = strNumber.slice(0, -3);
    }

    formattedNumber = strNumber + formattedNumber;
    return formattedNumber;
}

export async function copyToClipboardTextFrom(element: HTMLElement, options: {selectText: boolean} = {selectText: false}) {
	try {
		if (options.selectText) {
			const range = document.createRange();
			range.selectNode(element);
			window.getSelection()?.removeAllRanges();
			window.getSelection()?.addRange(range);
		}

		await navigator.clipboard.writeText(element.innerText);

		if (options.selectText) {
			window.getSelection()?.removeAllRanges();
		}

		return true
	}
	catch (err) {
		console.error('Failed to copy: ', err);
		return false
	}
}
