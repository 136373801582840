import { LoaderFunctionArgs } from "react-router-dom";

import { IPlan } from "@glocal/schemas/src/plan/plan";

import { trpc } from "../trpc";
import { formatNumber } from "../utils";

export async function loader({ params }: LoaderFunctionArgs) {
    const { planID } = params
    if (!planID) {
        throw new Error('400 Bad Request')
    }

    const plan = await trpc().PlanService.Get.query(planID)
    if (!plan) {
        throw new Error('404 Not Found')
    }

    return { plan }
}

export function PlanView(props: {plan: IPlan, linked?: boolean}) {
    const {plan, linked, } = props;
    const additionalClasses = linked ? 'hover:bg-[#EBEBFF] cursor-pointer focus:ring-4 focus:ring-opacity-50 focus:ring-[#FF73E1]' : ''
    return <div
        className={`p-4 flex flex-col gap-2 rounded-2xl bg-[#F5F5FF] outline-0 ${additionalClasses}`}
    >
        <div className="flex items-center justify-between gap-1">
            <span className="font-semibold">{plan.name}</span>
            <span className="text-[#8D73FF]">{plan.price}$</span>
        </div>
        <div className="flex items-center gap-1">
            <div className="p-4 flex flex-col items-center gap-2 rounded-2xl outline-0">
                <table>
                    <tbody>
                    <tr>
                        <td className="font-semibold w-72">ID</td>
                        <td>{plan.id}</td>
                    </tr>
                    <tr>
                        <td className="font-semibold">Name</td>
                        <td>{plan.name}</td>
                    </tr>
                    {plan.description &&
                        <tr>
                            <td className="font-semibold">Description</td>
                            <td>{plan.description}</td>
                        </tr>
                    }
                    <tr>
                        <td className="font-semibold">Requests limit</td>
                        <td>{formatNumber(plan.limitRequests)}</td>
                    </tr>
                    <tr>
                        <td className="font-semibold">Translate symbols limit</td>
                        <td>{formatNumber(plan.limitTranslateSymbols)}</td>
                    </tr>
                    <tr>
                        <td className="font-semibold">Cache time (s.)</td>
                        <td>{plan.cacheTime ? formatNumber(plan.cacheTime / 1_000) : '-'}</td>
                    </tr>
                    <tr>
                        <td className="font-semibold">Glossary</td>
                        <td>{plan.includeGlossary ? '✅' : '✖️'}</td>
                    </tr>
                    <tr>
                        <td className="font-semibold">Cookies</td>
                        <td>{plan.includeCookie ? '✅' : '✖️'}</td>
                    </tr>
                    <tr>
                        <td className="font-semibold">Image optimization</td>
                        <td>{plan.includeImageOptimization ? '✅' : '✖️'}</td>
                    </tr>
                    <tr>
                        <td className="font-semibold">Meta tags</td>
                        <td>{plan.includeMetaTags ? '✅' : '✖️'}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
}
