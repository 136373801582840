import type { AppRouter } from '@glocal/api/src/server'

import { createTRPCProxyClient, httpBatchLink } from '@trpc/client'

export function trpc() {
	return createTRPCProxyClient<AppRouter>({
		links: [
			httpBatchLink({
				url: import.meta.env.VITE_RPC_URL,
			}),
		],
	})
}
