import { createBrowserRouter, NavLink, type RouteObject } from 'react-router-dom'

import * as Sentry from '@sentry/react'

import { Account, loader as accountLoader } from './routes/account'
import { action as accountDestroy } from './routes/account-destroy'
import { AccountEdit, loader as accountEditLoader } from './routes/account-edit'
import { AccountNew, action as accountNewAction } from './routes/account-new'
import { loader as planLoader, Plan } from "./routes/plan";
import { action as planEditAction, loader as planEditLoader, PlanEdit } from "./routes/plan-edit";
import { action as planNewAction, PlanNew } from "./routes/plan-new";
import { loader as plansLoader, Plans } from "./routes/plans";
import { loader as rootLoader, Root } from './routes/root'
import { loader as websiteLoader, Website } from './routes/website'
import { action as websiteDestroy } from './routes/website-destroy'
import { action as websiteEditAction, loader as websiteEditLoader, WebsiteEdit } from './routes/website-edit'
import { action as websiteNewAction, loader as websiteNewLoader, WebsiteNew } from './routes/website-new'

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter)

export const router = sentryCreateBrowserRouter([
	{
		id: 'root',
		path: '/',
		element: <Root />,
		loader: rootLoader,
		children: [
			{
				index: true,
				element: <div className="p-4 w-full max-w-md">
					<h1 className="mb-4">&nbsp;</h1>
					<NavLink
						to={`plans`}
						className="font-semibold text-[#8D73FF]"
					>Plans</NavLink>
					</div>,
			},
			{
				path: 'account/new',
				element: <AccountNew />,
				action: accountNewAction,
			},
			{
				path: 'account/:accountID',
				children: [
					{
						index: true,
						element: <Account />,
						loader: accountLoader,
					},
					{
						path: 'website/new',
						element: <WebsiteNew />,
						action: websiteNewAction,
						loader: websiteNewLoader,
					},
					{
						path: 'website/:websiteID',
						element: <Website />,
						loader: websiteLoader,
					},
					{
						path: 'website/:websiteID/edit',
						element: <WebsiteEdit />,
						loader: websiteEditLoader,
						action: websiteEditAction
					},
					{
						path: 'website/:websiteID/destroy',
						action: websiteDestroy,
					},
				],
			},
			{
				path: 'account/:accountID/edit',
				element: <AccountEdit />,
				loader: accountEditLoader,
			},
			{
				path: 'account/:accountID/destroy',
				action: accountDestroy,
			},
			{
				path: 'plans',
				element: <Plans/>,
				loader: plansLoader,
			},
			{
				path: 'plans/new',
				element: <PlanNew />,
				action: planNewAction,
			},
			{
				path: 'plans/:planID',
				element: <Plan />,
				loader: planLoader,
			},
			{
				path: 'plans/:planID/edit',
				element: <PlanEdit />,
				loader: planEditLoader,
				action: planEditAction
			},
		],
	},
] satisfies RouteObject[])
