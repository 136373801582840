import { redirect, type ActionFunctionArgs } from 'react-router-dom'

import { trpc } from '../trpc'

export async function action({ params }: ActionFunctionArgs) {
	const { accountID, websiteID } = params
	if (!websiteID) {
		throw new Error('400 Bad Request')
	}

	await trpc().WebsiteService.Delete.mutate(websiteID)

	return redirect(accountID ? `/account/${accountID}` : '/')
}
