import { useState } from "react";
import { type ActionFunctionArgs, Form, redirect, useLoaderData, useNavigation } from 'react-router-dom'

import { LANGUAGE_LIST } from '@glocal/lang'
import type { ILang } from '@glocal/schemas'
import { IPlan } from "@glocal/schemas/src/plan/plan";

import * as Checkbox from '@radix-ui/react-checkbox'
import * as Label from '@radix-ui/react-label'

import { IconCheck } from '../components/icon-check'
import { IconPlus } from '../components/icon-plus'
import { LanguageSelector } from '../components/language-selector'
import { PlanSelector } from "../components/plan-selector";
import { trpc } from '../trpc'

export async function action({ request, params }: ActionFunctionArgs) {
	const { accountID } = params

	const data = await request.formData()
	const website = await trpc().WebsiteService.Create.mutate({
		account_id: accountID!,
		plan_id: data.get('plan_id') as IPlan['id'],
		name: data.get('name') as string,
		lang: data.get('lang') as ILang,
		origin: new URL(data.get('origin') as string).origin,
		public: new URL((data.get('public') as string) || (data.get('origin') as string)).origin,
		active: true,
		preview: !!data.get('demo'),
		banner: !!data.get('banner'),
		available_languages: (data.get('available_languages') as string)
			.split(',')
			.map((lang) => lang.trim())
			.filter((lang) => LANGUAGE_LIST.includes(lang as unknown as ILang)) as ILang[],
		smart_translations: !!data.get('smart_translations')
	})

	if (data.get('demo')) {
		// Выполняться должно последовательно!
		for (const lang of website.available_languages) {
			const hostname = lang + '-' + website.name.replaceAll(/\W+/gm, '-').toLowerCase() + `.${import.meta.env['VITE_DOMAIN']}`

			await trpc().RouteService.Create.mutate({
				website_id: website.id,
				hostname,
				lang,
			})
		}
	} else {
		if (data.get('base-hostname')) {
			const hostname = new URL(website.public || website.origin).hostname

			await trpc().RouteService.Create.mutate({
				website_id: website.id,
				custom_hostname_id: await trpc().CloudflareService.CreateCustomHostname.mutate(hostname),
				hostname
			})
		}

		// Выполняться должно последовательно!
		for (const lang of website.available_languages) {
			const hostname = lang + '.' + new URL(website.public || website.origin).hostname
			await trpc().RouteService.Create.mutate({
				website_id: website.id,
				custom_hostname_id: await trpc().CloudflareService.CreateCustomHostname.mutate(hostname),
				hostname,
				lang
			})
		}
	}

	return redirect(`/account/${accountID!}/website/${website.id}`)
}

export async function loader() {
	const plans = await trpc().PlanService.List.query()
	if (!plans) {
		throw new Error('404 Not Found')
	}

	return { plans: plans.plan_list }
}

export function WebsiteNew() {
	const getDefaultPlan = (plans: IPlan[]) => {
		let defaultValue = {...plans[0]}
		for(const plan of plans) {
			if(!defaultValue.price && plan.price) {
				defaultValue = plan
			}
			else if (defaultValue.price && plan.price && plan.price < defaultValue.price) {
				defaultValue = plan
			}
		}
		return defaultValue
	}

	const { plans } = useLoaderData() as { plans: IPlan[] }
	const [isDemo, setDemo] = useState<boolean>(!getDefaultPlan(plans).price);
	const [plan, setPlan] = useState<IPlan>(getDefaultPlan(plans));
	const navigation = useNavigation()
	const isSubmitting = navigation.state === 'submitting'

	const handlePlanChange = (plan: IPlan) => {
		const isDemo = !plan.price
		setDemo(isDemo)
		setPlan(plan)
	}

	return (
		<Form
			method="post"
			className="p-4 flex flex-col gap-4 w-full max-w-sm"
		>
			<h1 className="text-4xl">Create new website</h1>
			<input type="checkbox" name="demo" readOnly={true} checked={isDemo} style={{display: 'none'}}/>
			<div className="flex flex-col">
				<Label.Root
					className="mb-1 px-4 flex items-baseline"
					htmlFor="website-name"
				>
					<span className="font-medium text-sm">Name</span>
					<span className="ml-2 font-semibold text-xs text-[#B8B8B8]">
						minimum <b>4</b> characters
					</span>
				</Label.Root>
				<input
					id="website-name"
					className="field"
					name="name"
					type="text"
					placeholder="Enter website name, minimun 4 characters"
					autoFocus
					required
					minLength={4}
				/>
			</div>
			<div className="flex flex-col">
				<Label.Root
					className="mb-1 px-4 flex items-baseline"
					htmlFor="website-lang"
				>
					<span className="font-medium text-sm">Lang</span>
				</Label.Root>
				<LanguageSelector
					name="lang"
					required
				/>
			</div>
			<div className="flex flex-col">
				<Label.Root
					className="mb-1 px-4 flex items-baseline"
					htmlFor="wesbite-origin"
				>
					<span className="font-medium text-sm">Origin</span>
				</Label.Root>
				<input
					id="wesbite-origin"
					className="field"
					name="origin"
					type="url"
					placeholder="https://example.com"
					required
				/>
			</div>
			<div className="flex flex-col">
				<Label.Root
					className="mb-1 px-4 flex items-baseline"
					htmlFor="wesbite-public"
				>
					<span className="font-medium text-sm">Public</span>
					<span className="ml-2 font-semibold text-xs text-[#B8B8B8]">optional</span>
				</Label.Root>
				<input
					id="wesbite-public"
					className="field"
					name="public"
					type="url"
					placeholder="https://example.com"
				/>
			</div>
			<div className="flex flex-col">
				<Label.Root
					className="mb-1 px-4 flex items-baseline"
					htmlFor="plan"
				>
					<span className="font-medium text-sm">Plan</span>
				</Label.Root>
				<PlanSelector
					name="plan_id"
					required
					plans={plans}
					plan={plan}
					onChange={handlePlanChange}
				/>
			</div>
			<div className="flex flex-col">
				<Label.Root
					className="mb-1 px-4 flex items-baseline"
					htmlFor="wesbite-available-languages"
				>
					<span className="font-medium text-sm">Available Languages</span>
					<span className="ml-2 font-semibold text-xs text-[#B8B8B8]">
						supported <b>88</b> languages
					</span>
				</Label.Root>
				<input
					id="wesbite-available-languages"
					className="field"
					name="available_languages"
					type="text"
					placeholder="Example: ru, en, it, de, fr, ch"
					required
					minLength={2}
				/>
			</div>


			<div className="mt-2 flex items-center">
				<Checkbox.Root
					id="website-base-hostname"
					className="check"
					name="base-hostname"
				>
					<Checkbox.Indicator className="text-violet11">
						<IconCheck size={20} />
					</Checkbox.Indicator>
				</Checkbox.Root>
				<Label.Root
					className="px-4 flex items-baseline"
					htmlFor="website-base-hostname"
				>
					<span className="font-medium text-sm">Include base hostname</span>
				</Label.Root>
			</div>

			<div className="mt-2 flex items-center">
				<Checkbox.Root
					id="website-smart-translations"
					className="check"
					name="smart_translations"
				>
					<Checkbox.Indicator className="text-violet11">
						<IconCheck size={20} />
					</Checkbox.Indicator>
				</Checkbox.Root>
				<Label.Root
					className="px-4 flex items-baseline"
					htmlFor="website-smart-translations"
				>
					<span className="font-medium text-sm">Smart translations</span>
				</Label.Root>
			</div>

			{/*<div className="mt-2 flex items-center">*/}
			{/*	<Checkbox.Root*/}
			{/*		id="website-demo-stand"*/}
			{/*		className="check"*/}
			{/*		name="demo"*/}
			{/*	>*/}
			{/*		<Checkbox.Indicator className="text-violet11">*/}
			{/*			<IconCheck size={20} />*/}
			{/*		</Checkbox.Indicator>*/}
			{/*	</Checkbox.Root>*/}
			{/*	<Label.Root*/}
			{/*		className="px-4 flex items-baseline"*/}
			{/*		htmlFor="website-demo-stand"*/}
			{/*	>*/}
			{/*		<span className="font-medium text-sm">Create a demo stand</span>*/}
			{/*	</Label.Root>*/}
			{/*</div>*/}
			{/*<input type="checkbox" name="demo" ref={demoInputRef} defaultChecked={false} style={{display: 'none'}}/>*/}

			<div className="mt-2 flex items-center">
				<Checkbox.Root
					id="website-banner"
					className="check"
					name="banner"
				>
					<Checkbox.Indicator className="text-violet11">
						<IconCheck size={20} />
					</Checkbox.Indicator>
				</Checkbox.Root>
				<Label.Root
					className="px-4 flex items-baseline"
					htmlFor="website-banner"
				>
					<span className="font-medium text-sm">Place a banner</span>
				</Label.Root>
			</div>
			<button
				type="submit"
				className="mt-4 btn inline-flex w-auto"
				disabled={isSubmitting}
			>
				<IconPlus />
				<span className="font-semibold">{isSubmitting ? 'Creating...' : 'Create website'}</span>
			</button>
		</Form>
	)
}
