import { useEffect } from 'react'
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom'

import * as Sentry from '@sentry/react'

import pkg from '../package.json'

Sentry.init({
	enabled: import.meta.env.PROD,
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	dsn: import.meta.env['VITE_SENTRY_DSN'],
	release: pkg.version,
	environment: import.meta.env.MODE !== 'localhost' ? 'production' : "development",
	integrations: [
		new Sentry.Replay({
			blockAllMedia: true,
			maskAllText: false,
		}),
		new Sentry.BrowserTracing({
			tracePropagationTargets: ['localhost', /^\//, 'api.glocal.workers.dev', 'api.gloc.al'],
			routingInstrumentation: Sentry.reactRouterV6Instrumentation(
				useEffect,
				useLocation,
				useNavigationType,
				createRoutesFromChildren,
				matchRoutes
			),
		}),
	],
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
	tracesSampleRate: 1.0,
})
