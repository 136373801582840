import { Outlet } from 'react-router-dom'

import { Theme } from "@radix-ui/themes";

import { Sidebar } from '../components/sidebar'
import { trpc } from '../trpc'

export async function loader() {
	return trpc().AccountService.List.query()
}

export function Root() {
	return (
		<Theme>
			<div className="flex gap-4 h-screen">
				<Sidebar />
				<Outlet />
			</div>
		</Theme>
	)
}
