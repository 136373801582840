import { LANGUAGE_LIST_BY_POPULARITY, LANGUAGE_NAME_LIST } from '@glocal/lang'

import * as Select from '@radix-ui/react-select'

import { IconCheck } from './icon-check'
import { IconChevronDown } from './icon-chevron-down'

export function LanguageSelector(props: Select.SelectProps) {
	return (
		<Select.Root {...props}>
			<Select.Trigger
				id="website-lang"
				className="field flex items-center justify-between data-[placeholder]:text-[#A9A8C7]"
			>
				<Select.Value placeholder="Select website lang..." />
				<Select.Icon>
					<IconChevronDown />
				</Select.Icon>
			</Select.Trigger>

			<Select.Portal>
				<Select.Content className="z-10">
					<Select.Viewport className="bg-white rounded-xl outline outline-2 outline-[#01001A]">
						{Object.keys(LANGUAGE_LIST_BY_POPULARITY).map((popularity) => (
							<Select.Group key={popularity}>
								<Select.Label className="sticky top-2 translate-x-1/2 -ml-16 z-50">
									<span className="p-2 py-1 flex justify-center w-16 rounded-xl bg-[#E1E1FF] font-bold text-xs whitespace-nowrap select-none">
										{popularity}
									</span>
								</Select.Label>
								{LANGUAGE_LIST_BY_POPULARITY[popularity].map((lang) => (
									<Select.Item
										key={lang}
										className="relative px-4 flex items-center gap-2 h-10 select-none outline-0 data-[highlighted]:bg-[#FF73E1] data-[highlighted]:bg-opacity-50"
										value={lang}
									>
										<Select.ItemIndicator className="absolute">
											<IconCheck color="#7673FE" />
										</Select.ItemIndicator>
										<div className="ml-8 flex items-center justify-between w-full">
											<Select.ItemText className="flex gap-2 text-semibold">
												<span className="font-mono font-bold">{lang.toLocaleUpperCase()} </span>
												<span>{LANGUAGE_NAME_LIST[lang].name}</span>
											</Select.ItemText>
											<span className="text-xs">{LANGUAGE_NAME_LIST[lang].nativeName}</span>
										</div>
									</Select.Item>
								))}
							</Select.Group>
						))}
					</Select.Viewport>
					<Select.Arrow />
				</Select.Content>
			</Select.Portal>
		</Select.Root>
	)
}
