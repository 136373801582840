import { NavLink, useNavigate, useRouteLoaderData } from 'react-router-dom'

import * as ScrollArea from '@radix-ui/react-scroll-area'

import type { loader as rootLoader } from '../routes/root'

import { Avatar } from './avatar'
import { IconPlus } from './icon-plus'

export function Sidebar() {
	const navigate = useNavigate()
	const { account_list } = useRouteLoaderData('root') as Awaited<ReturnType<typeof rootLoader>>

	return (
		<ScrollArea.Root className="p-4 flex flex-col overflow-hidden">
			<h1 className="mb-4 text-4xl">Accounts</h1>

			<ScrollArea.Viewport className="p-4 w-72 rounded-2xl bg-[#F5F5FF]">
				<ul className="flex flex-col gap-1">
					{account_list.map((account) => (
						<li
							key={account.id}
							className="flex flex-col gap-2"
						>
							<NavLink
								to={`/account/${account.id}`}
								state={account}
								className="group/link p-1 flex items-center gap-3 rounded-xl outline-0 cursor-pointer"
							>
								{({ isActive }) => (
									<>
										<Avatar
											title={account.name}
											variant="bauhaus"
											active={isActive}
										/>

										<div className="mb-1 flex flex-col">
											<span className="font-semibold text-[#01001A]">{account.name}</span>
											<span className="text-xs text-[#8D73FF]">{account.email}</span>
										</div>
									</>
								)}
							</NavLink>
						</li>
					))}
				</ul>
			</ScrollArea.Viewport>

			<ScrollArea.Scrollbar orientation="vertical">
				<ScrollArea.Thumb />
			</ScrollArea.Scrollbar>

			<button
				className="mt-4 btn bg-[#73FFE5] text-[#01001A]"
				onClick={() => navigate('/account/new')}
			>
				<IconPlus />
				<span className="font-semibold">Create account</span>
			</button>
		</ScrollArea.Root>
	)
}
