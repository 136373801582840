import { createRef } from "react";
import { type LoaderFunctionArgs, useLoaderData, useNavigate } from 'react-router-dom'

import { LANGUAGE_NAME_LIST } from "@glocal/lang";
import { GetStatsResponse } from "@glocal/types";

import { Button, Code, IconButton, Table, Tooltip } from '@radix-ui/themes'

import { IconCopy } from "../components/icon-copy";
import { IconEdit } from '../components/icon-edit'
import { trpc } from '../trpc'
import { copyToClipboardTextFrom, formatNumber } from "../utils";

export async function loader({ params }: LoaderFunctionArgs) {
	const { websiteID } = params
	if (!websiteID) {
		throw new Error('400 Bad Request')
	}

	const website = await trpc().WebsiteService.Get.query(websiteID)
	if (!website) {
		throw new Error('404 Not Found')
	}

	const { route_list } = await trpc().WebsiteService.ListRoutes.query({ website_id: website.id })

	const custom_hostname_list = await Promise.all(
		route_list
			.filter((route) => Boolean(route.custom_hostname_id))
			.map((route) => trpc().CloudflareService.GetCustomHostname.query(route.custom_hostname_id!))
	)

	let usage: {translatedSymbols: number; requests: number}
	if (website.preview) {
		usage = await trpc().AnalyticsService.GetWebsiteUsage.query({websiteID: website.id})
	}
	else {
		const headers = new Headers()
		headers.set('Content-Type', 'application/json')
		headers.set('Authorization', `Bearer ${import.meta.env.VITE_GO_SERVER_TOKEN}`)
		const res = await fetch(`${import.meta.env.VITE_GO_SERVER_URL}/website/${website.id}/stats`, {
			method: 'GET',
			headers,
		})

		const json = await res.json<GetStatsResponse>()
		usage = {
			translatedSymbols: json.totalCharsTranslated,
			requests: json.totalRequests
		}
	}

	return { website, route_list, custom_hostname_list, usage }
}

export function Website() {
	const navigate = useNavigate()
	const { website, route_list, custom_hostname_list, usage } = useLoaderData() as Awaited<ReturnType<typeof loader>>
	const routesRef = createRef<HTMLDivElement>()

	const copyDnsRecordsForRoute = async (i: number) => {
		const element = routesRef.current?.querySelectorAll('.route')[i] as HTMLElement
		await copyToClipboardTextFrom(element)
	}

	const copyAllDnsRecords = async () => {
		const element = routesRef.current as HTMLElement
		await copyToClipboardTextFrom(element)
	}

	const script = `<script src="https://${import.meta.env.VITE_DOMAIN}/js/lang-switcher.js?website_id=${website.id}"></script>`

	return (
		<div className="p-4 w-full max-w-md">
			<div className="mb-4 flex justify-between">
				<h1 className="text-4xl truncate">{website.name}</h1>

				<button
					className="ml-4 btn btn-ghost"
					onClick={() => navigate(`edit`)}
				>
					<IconEdit size={18} />
					<span className="font-semibold">Edit</span>
				</button>
			</div>
			<nav>
				<Table.Root variant={'surface'} className="mb-4">
					<Table.Header>
						<Table.Row>
							<Table.ColumnHeaderCell colSpan={2} justify={'center'}>Usage in the last 30 days</Table.ColumnHeaderCell>
						</Table.Row>
					</Table.Header>

					<Table.Body>
						<Table.Row>
							<Table.RowHeaderCell>Requests</Table.RowHeaderCell>
							<Table.Cell>{formatNumber(usage.requests)}</Table.Cell>
						</Table.Row>

						<Table.Row>
							<Table.RowHeaderCell>Translated symbols</Table.RowHeaderCell>
							<Table.Cell>{formatNumber(usage.translatedSymbols)}</Table.Cell>
						</Table.Row>
					</Table.Body>
				</Table.Root>

				{
					!website.preview &&
					<>
						<details className="p-4 border rounded-2xl border-gray-300 mb-4 relative">
							<summary className="cursor-pointer select-none relative">
								Language switcher script
							</summary>
							<div className="code-wrapper mt-2">
								<Code>
									{script}
								</Code>
							</div>
						</details>
						<details className="p-4 border rounded-2xl border-gray-300 mb-4 relative">
							<summary className="cursor-pointer select-none relative">
								DNS записи
							</summary>
							<div className="details-container flex flex-col gap-4">
								{/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
								<Button variant="outline" className="cursor-pointer w-full mt-4" onClick={() => copyAllDnsRecords()}>
									<IconCopy size={18}/> Скопировать все
								</Button>

								<div className="dns-records flex flex-col" ref={routesRef}>
									{
										route_list.map((route, i) => (<>
												<div className={`route flex flex-col rounded-2xl bg-${custom_hostname_list[i]?.status === 'active' && custom_hostname_list[i]?.ssl.status === 'active' ? 'active' : '[#F5F5FF]'} outline-0 p-4 relative`} key={route.id}>
													<Tooltip content="Copy">
														{/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
														<IconButton className="cursor-pointer absolute right-4" variant="outline" onClick={() => copyDnsRecordsForRoute(i)}>
															<IconCopy size={18}></IconCopy>
														</IconButton>
													</Tooltip>
													<h4 className="text-center font-semibold">{LANGUAGE_NAME_LIST[route.lang!]!.name}</h4>
													<div className="dns-record-item">
														<h5>CNAME</h5>
														<div className="font-semibold">Name:</div>
														<div><Code>{route.hostname}</Code></div>
														<div className="font-semibold">Value:</div>
														<div><Code>{import.meta.env['VITE_DOMAIN']}</Code></div>
													</div>
													<br/>

													<div className="dns-record-item">
														<h5>TXT-1</h5>
														<div className="font-semibold">Name:</div>
														<div><Code>{custom_hostname_list[i]?.ownership_verification?.name}</Code></div>
														<div className="font-semibold">Value:</div>
														<div><Code>{custom_hostname_list[i]?.ownership_verification?.value}</Code></div>
													</div>
													<br/>

													<div className="dns-record-item">
														<h5>TXT-2</h5>
														<div className="font-semibold">Name:</div>
														<div><Code>{custom_hostname_list[i]?.ssl.txt_name}</Code></div>
														<div className="font-semibold">Value:</div>
														<div><Code>{custom_hostname_list[i]?.ssl.txt_value}</Code></div>
													</div>
												</div>
												{
													i < route_list.length - 1 &&
													<><br/><br/></>
												}
											</>
										))
									}
								</div>
							</div>

						</details>
					</>
				}


				{
					website.preview &&
					<ul className="flex flex-col gap-4">
						{route_list.map((route, i) => (
							<div className="p-4 flex flex-col items-center gap-2 rounded-2xl bg-[#F5F5FF] outline-0 hover:bg-[#EBEBFF] focus:ring-4 focus:ring-opacity-50 focus:ring-[#FF73E1]" key={'item'+i}>
								<div className="flex">{route.hostname}</div>
								{Boolean(route.custom_hostname_id) && Boolean(custom_hostname_list[i]) && (
									<>
										<div className="flex">{custom_hostname_list[i]!.status}</div>
										<div className="flex">{custom_hostname_list[i]!.ownership_verification?.type}</div>
										<div className="flex">{custom_hostname_list[i]!.ownership_verification?.name}</div>
										<div className="flex">{custom_hostname_list[i]!.ownership_verification?.value}</div>
										<div className="flex">{custom_hostname_list[i]!.ssl.status}</div>
										<div className="flex">{custom_hostname_list[i]!.ssl.txt_name}</div>
										<div className="flex">{custom_hostname_list[i]!.ssl.txt_value}</div>
									</>
								)}
							</div>
						))}
					</ul>
				}

			</nav>
		</div>
	)
}
