import BoringAvatar, { type AvatarProps as BoringAvatarProps } from 'boring-avatars'

type AvatarProps = BoringAvatarProps & {
	title: string
	active: boolean
}

export function Avatar({
	title = crypto.randomUUID(),
	size = 40,
	variant = 'beam',
	square = false,
	colors = ['#73FFE5', '#73B9FF', '#7673FE', '#D573FF', '#FF73E1'],
	active = false,
}: AvatarProps) {
	const search = new URLSearchParams()
	search.set('colors', colors.join())

	if (square) {
		search.set('square', '')
	}

	const classes = [
		'flex justify-center items-center',
		'rounded-full outline outline-2 outline-[#01001A]',
		'group-hover/link:ring-4 group-hover/link:ring-offset-1 group-hover/link:ring-opacity-50 group-hover/link:ring-[#FF73E1]',
		'group-focus/link:ring-4 group-focus/link:ring-offset-1 group-focus/link:ring-opacity-50 group-focus/link:ring-[#FF73E1]',
		square ? 'rounded-lg' : undefined,
		active ? 'ring-4 ring-offset-1 ring-opacity-70 ring-[#FF73E1]' : undefined,
	]
		.filter(Boolean)
		.join(' ')

	const name = encodeURIComponent(title)

	return (
		<span className={classes}>
			<BoringAvatar
				size={size}
				name={name}
				variant={variant}
				square={square}
				colors={colors}
			/>
		</span>
	)
}
