import { IPlan } from "@glocal/schemas/src/plan/plan";

import * as Checkbox from "@radix-ui/react-checkbox";
import * as Label from "@radix-ui/react-label";

import { preventExtraChars } from "../utils";

import { IconCheck } from "./icon-check";

export function PlanFieldsEdit(props: {plan?: IPlan}) {
	const {plan} = props;

	return <>
		<div className="flex flex-col">
			<label
				className="mb-1 px-4 flex items-baseline"
				htmlFor="plan-name"
			>
				<span className="font-medium text-sm">Name</span>
				<span className="ml-2 font-semibold text-xs text-[#B8B8B8]">
						minimum <b>4</b> characters
					</span>
			</label>
			<input
				id="plan-name"
				className="field"
				name="name"
				type="text"
				placeholder="Enter plan name, minimun 4 characters"
				autoFocus
				required
				minLength={4}
				defaultValue={plan?.name}
			/>
		</div>
		<div className="flex flex-col">
			<label
				className="mb-1 px-4 flex items-baseline"
				htmlFor="plan-description"
			>
				<span className="font-medium text-sm">Description</span>
			</label>
			<textarea
				rows={4}
				id="plan-description"
				className="field"
				name="description"
				placeholder="Enter plan description"
				defaultValue={plan?.description}
			/>
		</div>
		<div className="flex flex-col">
			<label
				className="mb-1 px-4 flex items-baseline"
				htmlFor="plan-price"
			>
				<span className="font-medium text-sm">Price, $</span>
			</label>
			<input
				id="plan-price"
				className="field"
				name="price"
				type="number"
				placeholder="Enter plan price ($)"
				required
				onKeyDown={ preventExtraChars }
				min={0}
				defaultValue={plan?.price}
			/>
		</div>
		<div className="flex flex-col">
			<label
				className="mb-1 px-4 flex items-baseline"
				htmlFor="plan-requests"
			>
				<span className="font-medium text-sm">Requests limit</span>
			</label>
			<input
				id="plan-requests"
				className="field"
				name="requests"
				type="number"
				placeholder="Enter plan requests limit"
				required
				onKeyDown={ preventExtraChars }
				min={0}
				defaultValue={plan?.limitRequests}
			/>
		</div>
		<div className="flex flex-col">
			<label
				className="mb-1 px-4 flex items-baseline"
				htmlFor="plan-chars"
			>
				<span className="font-medium text-sm">Translation chars limit</span>
			</label>
			<input
				id="plan-chars"
				className="field"
				name="chars"
				type="number"
				placeholder="Enter plan translation chars limit"
				required
				onKeyDown={ preventExtraChars }
				min={0}
				defaultValue={plan?.limitTranslateSymbols}
			/>
		</div>
		<div className="flex flex-col">
			<label
				className="mb-1 px-4 flex items-baseline"
				htmlFor="plan-cache-time"
			>
				<span className="font-medium text-sm">Cache time (s.)</span>
				<span className="ml-2 font-semibold text-xs text-[#B8B8B8]">
							0 - cache will be disabled
						</span>
			</label>
			<input
				id="plan-cache-time"
				className="field"
				name="cache-time"
				type="number"
				placeholder="Enter cache time for pages in seconds"
				required
				onKeyDown={ preventExtraChars }
				min={0}
				defaultValue={plan?.cacheTime / 1_000}
			/>
		</div>
		<div className="mt-2 flex items-center">
			<Checkbox.Root
				id="plan-glossary"
				className="check"
				name="glossary"
				defaultChecked={plan?.includeGlossary}
			>
				<Checkbox.Indicator className="text-violet11">
					<IconCheck size={20} />
				</Checkbox.Indicator>
			</Checkbox.Root>
			<Label.Root
				className="px-4 flex items-baseline"
				htmlFor="plan-glossary"
			>
				<span className="font-medium text-sm">Glossary</span>
			</Label.Root>
		</div>
		<div className="mt-2 flex items-center">
			<Checkbox.Root
				id="plan-cookies"
				className="check"
				name="cookies"
				defaultChecked={plan?.includeCookie}
			>
				<Checkbox.Indicator className="text-violet11">
					<IconCheck size={20} />
				</Checkbox.Indicator>
			</Checkbox.Root>
			<Label.Root
				className="px-4 flex items-baseline"
				htmlFor="plan-cookies"
			>
				<span className="font-medium text-sm">Cookies</span>
			</Label.Root>
		</div>
		<div className="mt-2 flex items-center">
			<Checkbox.Root
				id="plan-image-optimization"
				className="check"
				name="image-optimization"
				defaultChecked={plan?.includeImageOptimization}
			>
				<Checkbox.Indicator className="text-violet11">
					<IconCheck size={20} />
				</Checkbox.Indicator>
			</Checkbox.Root>
			<Label.Root
				className="px-4 flex items-baseline"
				htmlFor="plan-image-optimization"
			>
				<span className="font-medium text-sm">Image optimization</span>
			</Label.Root>
		</div>
		<div className="mt-2 flex items-center">
			<Checkbox.Root
				id="plan-meta-tags"
				className="check"
				name="meta-tags"
				defaultChecked={plan?.includeMetaTags}
			>
				<Checkbox.Indicator className="text-violet11">
					<IconCheck size={20} />
				</Checkbox.Indicator>
			</Checkbox.Root>
			<Label.Root
				className="px-4 flex items-baseline"
				htmlFor="plan-meta-tags"
			>
				<span className="font-medium text-sm">Meta tags</span>
			</Label.Root>
		</div>
	</>
}
