import type { FormEvent } from 'react'
import { type LoaderFunctionArgs, useLoaderData, Form } from 'react-router-dom'

import type { IAccount } from '@glocal/schemas'

import { IconTrash } from '../components/icon-trash'
import { trpc } from '../trpc'

export async function loader({ params }: LoaderFunctionArgs) {
	const { accountID } = params
	if (!accountID) {
		throw new Error('400 Bad Request')
	}

	const account = await trpc().AccountService.Get.query(accountID)
	if (!account) {
		throw new Error('404 Not Found')
	}

	return { account }
}

export function AccountEdit() {
	const { account } = useLoaderData() as { account: IAccount }

	function onDelete(e: FormEvent<HTMLFormElement>) {
		if (!confirm(`Сonfirm account deletion`)) {
			e.preventDefault()
		}
	}

	return (
		<Form
			action={`/account/${account.id}/destroy`}
			method="post"
			className="p-4 flex flex-col gap-4 w-full max-w-sm"
			onSubmit={onDelete}
		>
			<div className="mb-4 flex justify-between">
				<h1 className="text-4xl truncate">{account.name}</h1>
			</div>

			<input
				id="account-id"
				name="id"
				type="hidden"
				value={account.id}
			/>

			<button
				className="btn btn-danger"
				type="submit"
			>
				<IconTrash />
				<span className="font-semibold">Destroy Account</span>
			</button>
		</Form>
	)
}
