import type { FormEvent } from 'react'
import { useState } from "react";
import {
	ActionFunctionArgs,
	Form,
	type LoaderFunctionArgs,
	redirect,
	useLoaderData,
	useNavigation,
	useParams
} from 'react-router-dom'

import { Plan } from "@glocal/models";
import type { IWebsite } from '@glocal/schemas'
import { IPlan } from "@glocal/schemas/src/plan/plan";

import * as Checkbox from '@radix-ui/react-checkbox'
import * as Label from '@radix-ui/react-label'

import { IconCheck } from '../components/icon-check'
import { IconPencil } from "../components/icon-pencil";
import { IconTrash } from '../components/icon-trash'
import { PlanSelector } from "../components/plan-selector";
import { trpc } from '../trpc'
import { formatNumber, preventExtraChars } from "../utils";

export async function action({ request }: ActionFunctionArgs) {
	const data = await request.formData()
	const websiteID = data.get('id') as Plan['id']
	await trpc().WebsiteService.Update.mutate({
		id: websiteID,
		plan_id: data.get('plan_id') as string,
		banner: !!data.get('banner'),
		smart_translations: !!data.get('smart_translations'),
		additional_plan_params: {
			limitRequests: Number(data.get('requests')),
			limitTranslateSymbols: Number(data.get('chars')),
		}
	})

	const accountID = data.get('account_id')

	return redirect(`/account/${accountID!}/website/${websiteID}`)
}

export async function loader({ params }: LoaderFunctionArgs) {
	const { websiteID } = params
	if (!websiteID) {
		throw new Error('400 Bad Request')
	}

	const website = await trpc().WebsiteService.Get.query(websiteID)
	if (!website) {
		throw new Error('404 Not Found')
	}

	const plans = await trpc().PlanService.List.query()
	if (!plans) {
		throw new Error('404 Not Found Plans')
	}

	plans.plan_list = plans.plan_list.filter(plan => {
		if (website.preview) {
			return !plan.price
		}

		return plan.price > 0
	})

	return { website, plans: plans.plan_list }
}

export function WebsiteEdit() {
	const { accountID } = useParams()
	const { website, plans } = useLoaderData() as { website: IWebsite; plans: IPlan[] }
	const navigation = useNavigation()
	const isSubmitting = navigation.state === 'submitting'

	const [plan, setPlan] = useState<IPlan | undefined>(plans.find(item => item.id === website.plan_id));

	const handlePlanChange = (plan: IPlan) => {
		setPlan(plan)
	}


	function onDelete(e: FormEvent<HTMLFormElement>) {
		if (!confirm(`Confirm website deletion`)) {
			e.preventDefault()
		}
	}

	return (
		<div className="p-4 flex flex-col gap-4 w-full max-w-sm">
			<div className="mb-4 flex justify-between">
				<h1 className="text-4xl truncate">{website.name}</h1>
			</div>

			<Form
				action={`/account/${accountID!}/website/${website.id}/edit`}
				method="post"
				className="flex flex-col gap-4"
			>
				<input type="hidden" name="id" value={website.id}/>
				<input type="hidden" name="account_id" value={website.account_id}/>
				<div className="flex flex-col">
					<Label.Root
						className="mb-1 px-4 flex items-baseline"
						htmlFor="plan"
					>
						<span className="font-medium text-sm">Plan</span>
					</Label.Root>
					<PlanSelector
						name="plan_id"
						required
						plans={plans}
						plan={plan}
						onChange={handlePlanChange}
					/>
				</div>
				<details className="p-4 rounded-2xl bg-[#F5F5FF]">
					<summary className="cursor-pointer select-none">Additional plan params</summary>
					<div className="plan-params flex flex-col gap-4">
						<div className="flex flex-col mt-2">
							<label
								className="mb-1 flex items-baseline"
								htmlFor="additional-requests"
							>
								<span className="font-medium text-sm">Requests</span>
								{
									plan &&
									<span className="ml-2 font-semibold text-xs text-[#B8B8B8]">
										<b>{formatNumber(plan.limitRequests)}</b> already set by plan
									</span>
								}
							</label>
							<input
								id="additional-requests"
								className="field"
								name="requests"
								type="number"
								placeholder="Add requests limit"
								required
								onKeyDown={ preventExtraChars }
								min={0}
								defaultValue={website.additional_plan_params.limitRequests}
							/>
						</div>
						<div className="flex flex-col">
							<label
								className="mb-1 flex items-baseline"
								htmlFor="additional-chars"
							>
								<span className="font-medium text-sm">Translation chars</span>
								{
									plan &&
									<span className="ml-2 font-semibold text-xs text-[#B8B8B8]">
										<b>{formatNumber(plan.limitTranslateSymbols)}</b> already set by plan
									</span>
								}
							</label>
							<input
								id="additional-chars"
								className="field"
								name="chars"
								type="number"
								placeholder="Add translation chars limit"
								required
								onKeyDown={ preventExtraChars }
								min={0}
								defaultValue={website.additional_plan_params.limitTranslateSymbols}
							/>
						</div>
					</div>
				</details>
				<div className="mt-2 flex items-center">
					<Checkbox.Root
						id="website-banner"
						className="check"
						name="banner"
						defaultChecked={website?.banner}
					>
						<Checkbox.Indicator className="text-violet11">
							<IconCheck size={20} />
						</Checkbox.Indicator>
					</Checkbox.Root>
					<Label.Root
						className="px-4 flex items-baseline"
						htmlFor="website-banner"
					>
						<span className="font-medium text-sm">Place a banner</span>
					</Label.Root>
				</div>
				<div className="mt-2 flex items-center">
					<Checkbox.Root
						id="website-smart-translations"
						className="check"
						name="smart_translations"
						defaultChecked={website?.smart_translations}
					>
						<Checkbox.Indicator className="text-violet11">
							<IconCheck size={20} />
						</Checkbox.Indicator>
					</Checkbox.Root>
					<Label.Root
						className="px-4 flex items-baseline"
						htmlFor="website-smart-translations"
					>
						<span className="font-medium text-sm">Smart translations</span>
					</Label.Root>
				</div>
				<button
					type="submit"
					className="mt-4 btn inline-flex w-auto"
					disabled={isSubmitting}
				>
					<IconPencil />
					<span className="font-semibold">{isSubmitting ? 'Saving...' : 'Save Website'}</span>
				</button>
			</Form>

			<hr />

			<Form
				action={`/account/${accountID!}/website/${website.id}/destroy`}
				method="post"
				className="flex flex-col gap-4"
				onSubmit={onDelete}
			>
				<button
					className="btn btn-danger"
					type="submit"
				>
					<IconTrash />
					<span className="font-semibold">Destroy Website</span>
				</button>
			</Form>
		</div>
	)
}
