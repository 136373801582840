import { LoaderFunctionArgs, useLoaderData, useNavigate } from "react-router-dom";

import { IPlan } from "@glocal/schemas/src/plan/plan";

import { IconEdit } from "../components/icon-edit";
import { PlanView } from "../components/plan-view";
import { trpc } from "../trpc";

export async function loader({ params }: LoaderFunctionArgs) {
    const { planID } = params
    if (!planID) {
        throw new Error('400 Bad Request')
    }

    const plan = await trpc().PlanService.Get.query(planID)
    if (!plan) {
        throw new Error('404 Not Found')
    }

    return { plan }
}

export function Plan() {
	const navigate = useNavigate()
    const { plan } = useLoaderData() as { plan: IPlan; }

    return <>
        <div className="p-4 w-full max-w-md">
            <div className="mb-4 flex justify-between">
                <h1 className="text-4xl truncate">Plan</h1>
				<button
					className="ml-4 btn btn-ghost"
					onClick={() => navigate(`edit`)}
				>
					<IconEdit size={18} />
					<span className="font-semibold">Edit plan</span>
				</button>
            </div>
            <PlanView plan={plan}/>
        </div>
    </>
}
