import { redirect, type ActionFunctionArgs } from 'react-router-dom'

import { trpc } from '../trpc'

export async function action({ request }: ActionFunctionArgs) {
	const data = await request.formData()

	const accountID = data.get('id') as string | undefined
	if (!accountID) {
		throw new Error('400 Bad Request')
	}

	await trpc().AccountService.Delete.mutate(accountID)

	return redirect(`/`)
}
