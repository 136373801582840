import { type ActionFunctionArgs, Form, redirect } from 'react-router-dom'

import { IconPlus } from '../components/icon-plus'
import { PlanFieldsEdit } from "../components/plan-fields-edit";
import { trpc } from '../trpc'


export async function action({ request }: ActionFunctionArgs) {
    const data = await request.formData()
    const plan = await trpc().PlanService.Create.mutate({
        name: data.get('name') as string,
		description: data.get('description') as string,
        price: Number(data.get('price')),
		limitRequests: Number(data.get('requests')),
		limitTranslateSymbols: Number(data.get('chars')),
		cacheTime: Number(data.get('cache-time')) * 1_000,
		includeCookie: Boolean(data.get('cookies')),
		includeGlossary: Boolean(data.get('glossary')),
		includeImageOptimization: Boolean(data.get('image-optimization')),
		includeMetaTags: Boolean(data.get('meta-tags')),
    })

    return redirect(`/plans/${plan.id}`)
}

export function PlanNew() {
    return (
        <Form
            method="post"
            className="p-4 flex flex-col gap-4 w-full max-w-sm"
        >
            <h1 className="text-4xl">Create new plan</h1>
			<PlanFieldsEdit></PlanFieldsEdit>
            <button
                type="submit"
                className="mt-4 btn inline-flex w-auto"
            >
                <IconPlus />
                <span className="font-semibold">Create plan</span>
            </button>
        </Form>
    )
}
