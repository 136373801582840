import { FormEvent } from "react";
import { ActionFunctionArgs, Form, LoaderFunctionArgs, redirect, useLoaderData, useNavigate } from "react-router-dom";

import { Plan } from "@glocal/models";
import { IWebsite } from "@glocal/schemas";
import { IPlan } from "@glocal/schemas/src/plan/plan";

import { IconTrash } from "../components/icon-trash";
import { PlanFieldsEdit } from "../components/plan-fields-edit";
import { trpc } from "../trpc";

export async function action({ request }: ActionFunctionArgs) {
	const data = await request.formData()
	const planID = data.get('id') as Plan['id']
	await trpc().PlanService.Update.mutate({
		id: planID,
		name: data.get('name') as string,
		description: data.get('description') as string,
		price: Number(data.get('price')),
		limitRequests: Number(data.get('requests')),
		limitTranslateSymbols: Number(data.get('chars')),
		cacheTime: Number(data.get('cache-time')) * 1_000,
		includeCookie: Boolean(data.get('cookies')),
		includeGlossary: Boolean(data.get('glossary')),
		includeImageOptimization: Boolean(data.get('image-optimization')),
		includeMetaTags: Boolean(data.get('meta-tags')),
	})

	return redirect(`/plans/${planID}`)
}

export async function loader({ params }: LoaderFunctionArgs) {
	const { planID } = params
	if (!planID) {
		throw new Error('400 Bad Request')
	}

	const plan = await trpc().PlanService.Get.query(planID)
	if (!plan) {
		throw new Error('404 Not Found')
	}

	const binded_website_list = await trpc().PlanService.GetWebsiteIDs.query(planID)

	return { plan, binded_website_IDs: binded_website_list.list }
}

export function PlanEdit() {
	const navigate = useNavigate()
	const { plan, binded_website_IDs } = useLoaderData() as { plan: IPlan; binded_website_IDs: string[] }
	const onSubmit = (e: FormEvent<HTMLFormElement>) => {
		if (!confirm(`Сonfirm plan changes`)) {
			e.preventDefault()
		}
	}

	const deleteHandler = async () => {
		if (binded_website_IDs.length) {
			const promises = binded_website_IDs.map(id => {
				return trpc().WebsiteService.Get.query(id)
			})

			const websites = (await Promise.all(promises)).filter(Boolean) as IWebsite[]
			const websiteNames = websites.map(website => website.name).join(', ')
			if (websites.length) {
				alert(`Can't remove plan while it binds to websites: ${websiteNames}.`)
				return;
			}
		}

		await trpc().PlanService.Delete.mutate(plan.id)

		navigate(`/plans`)
	}

	return (<div className="flex flex-col gap-4 w-full max-w-sm p-4">
		<div className="flex justify-between items-center">
			<h1 className="text-4xl">{plan.name}</h1>
			<div className="flex items-center gap-2">
				<button
					className="btn btn-danger p-2"
					type="button"
					onClick={deleteHandler}
				>
					<IconTrash />
				</button>
			</div>
		</div>
		<div>
			<span className="text-xs text-[#8D73FF]">{plan.id}</span>
		</div>
		<Form
			method="post"
			className="flex flex-col gap-4"
			onSubmit={onSubmit}
		>
			<input type="hidden" name="id" value={plan.id}/>
			<PlanFieldsEdit plan={plan}></PlanFieldsEdit>
			<button
				type="submit"
				className="mt-4 btn inline-flex w-auto"
			>
				<span className="font-semibold">Save</span>
			</button>
		</Form>
	</div>)
}
