import { IPlan } from "@glocal/schemas/src/plan/plan";

import * as Select from '@radix-ui/react-select'

import { IconCheck } from './icon-check'
import { IconChevronDown } from './icon-chevron-down'

export function PlanSelector(props: Select.SelectProps & {plans: IPlan[], onChange?: (plan: IPlan) => unknown, plan?: IPlan }) {
	const {onChange} = props;
	
	const handleChange = (planID: string) => {
		const plan = props.plans.find(item => item.id === planID);
		if(plan && onChange) {
			onChange({...plan});
		}
	};

	return (
		<Select.Root {...props} onValueChange={handleChange} value={props.plan?.id}>
			<Select.Trigger
				id="plan"
				className="field flex items-center justify-between data-[placeholder]:text-[#A9A8C7]"
			>
				<Select.Value placeholder="Select plan..." />
				<Select.Icon>
					<IconChevronDown />
				</Select.Icon>
			</Select.Trigger>

			<Select.Portal>
				<Select.Content className="z-10">
					<Select.Viewport className="bg-white rounded-xl outline outline-2 outline-[#01001A]">
								{props.plans.map((plan) => (
									<Select.Item
										key={plan.id}
										className="relative px-4 flex items-center gap-2 h-10 select-none outline-0 data-[highlighted]:bg-[#FF73E1] data-[highlighted]:bg-opacity-50"
										value={plan.id}
									>
										<Select.ItemIndicator className="absolute">
											<IconCheck color="#7673FE" />
										</Select.ItemIndicator>
										<div className="ml-8 flex items-center justify-between w-full">
											<Select.ItemText className="flex gap-2 text-semibold">
												{/*<span className="font-mono font-bold">{plan.name.toLocaleUpperCase()} </span>*/}
												<span>{plan.name}</span>
											</Select.ItemText>
											{/*style={{opacity: plan.price ? 1 : 0.5}}*/}
											<span className="text-xs" >{plan.price}$</span>
										</div>
									</Select.Item>
								))}
					</Select.Viewport>
					<Select.Arrow />
				</Select.Content>
			</Select.Portal>
		</Select.Root>
	)
}
