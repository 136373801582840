import { type ActionFunctionArgs, Form, redirect } from 'react-router-dom'

import { IconPlus } from '../components/icon-plus'
import { trpc } from '../trpc'

export async function action({ request }: ActionFunctionArgs) {
	const data = await request.formData()
	const account = await trpc().AccountService.Create.mutate({
		name: data.get('name') as string,
		email: data.get('email') as string,
		referer: data.get('email') as string,
	})

	return redirect(`/account/${account.id}`)
}

export function AccountNew() {
	return (
		<Form
			method="post"
			className="p-4 flex flex-col gap-4 w-full max-w-sm"
		>
			<h1 className="text-4xl">Create new account</h1>
			<div className="flex flex-col">
				<label
					className="mb-1 px-4 flex items-baseline"
					htmlFor="account-name"
				>
					<span className="font-medium text-sm">Name</span>
					<span className="ml-2 font-semibold text-xs text-[#B8B8B8]">
						minimun <b>4</b> characters
					</span>
				</label>
				<input
					id="account-name"
					className="field"
					name="name"
					type="text"
					placeholder="Enter account name, minimun 4 characters"
					autoFocus
					required
					minLength={4}
				/>
			</div>
			<div className="flex flex-col">
				<label
					className="mb-1 px-4 flex items-baseline"
					htmlFor="account-email"
				>
					<span className="font-medium text-sm">Email</span>
				</label>
				<input
					id="account-email"
					className="field"
					name="email"
					type="email"
					placeholder="Enter account email"
					required
				/>
			</div>
			<div className="flex flex-col">
				<label
					className="mb-1 px-4 flex items-baseline"
					htmlFor="account-referer"
				>
					<span className="font-medium text-sm">Referer</span>
					<span className="ml-2 font-semibold text-xs text-[#B8B8B8]">optional</span>
				</label>
				<input
					id="account-referer"
					className="field"
					name="referer"
					type="text"
					placeholder="Enter referer code, name or email"
				/>
			</div>
			<button
				type="submit"
				className="mt-4 btn inline-flex w-auto"
			>
				<IconPlus />
				<span className="font-semibold">Create account</span>
			</button>
		</Form>
	)
}
