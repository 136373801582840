import { type LoaderFunctionArgs, NavLink, useLoaderData, useNavigate } from 'react-router-dom'

import { IPlan } from "@glocal/schemas/src/plan/plan";

import { IconPlus } from "../components/icon-plus";
import { PlanView } from "../components/plan-view";
import { trpc } from '../trpc'

export async function loader({ params }: LoaderFunctionArgs) {
    const { plan_list } = await trpc().PlanService.List.query()

    return { plan_list: plan_list.sort((a, b) => a.name.localeCompare(b.name)).sort((a, b) => a.price - b.price) }
}

export function Plans() {
    const navigate = useNavigate()
    const { plan_list } = useLoaderData() as {plan_list: IPlan[] }

    return (
        <div className="p-4 w-full max-w-md">
            <div className="mb-4 flex justify-between">
                <h1 className="text-4xl truncate">Plans</h1>

                <button
                    className="ml-4 btn btn-ghost"
                    onClick={() => navigate(`new`)}
                >
                    <IconPlus />
                    <span className="font-semibold">Create plan</span>
                </button>
            </div>
            <nav>
                <ul className="flex flex-col gap-4">
                    {plan_list.map((plan) => (
                        <li key={plan.id}>
                            <NavLink
                                to={`${plan.id}`}
                            >
                                <PlanView plan={plan} linked={true}/>
                            </NavLink>
                        </li>
                    ))}
                </ul>
            </nav>
        </div>
    )
}
