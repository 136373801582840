import { type LoaderFunctionArgs, NavLink, useLoaderData, useNavigate } from 'react-router-dom'

import type { IAccount, IWebsite } from '@glocal/schemas'
import { IPlan } from "@glocal/schemas/src/plan/plan";

import { IconCompass } from '../components/icon-compass'
import { IconEdit } from '../components/icon-edit'
import { IconPin } from "../components/icon-pin";
import { IconPlus } from '../components/icon-plus'
import { trpc } from '../trpc'

export async function loader({ params }: LoaderFunctionArgs) {
	const { accountID } = params
	if (!accountID) {
		throw new Error('400 Bad Request')
	}

	const account = await trpc().AccountService.Get.query(accountID)
	if (!account) {
		throw new Error('404 Not Found')
	}

	const { website_list } = await trpc().AccountService.ListWebsites.query({ account_id: account.id })
	const { plan_list } = await trpc().PlanService.List.query()
	const plans: {[key: IPlan['id']]: IPlan} = {}
	plan_list.forEach((plan => {
		plans[plan.id] = plan
	}))

	return { account, website_list, plans }
}

export function Account() {
	const navigate = useNavigate()
	const { account, website_list, plans } = useLoaderData() as { account: IAccount; website_list: IWebsite[]; plans: {[key: IPlan['id']]: IPlan} }

	return (
		<div className="p-4 w-full max-w-md">
			<div className="mb-4 flex justify-between">
				<h1 className="text-4xl truncate">{account.name}</h1>

				<button
					className="ml-4 btn btn-ghost"
					onClick={() => navigate(`edit`)}
				>
					<IconEdit size={18} />
					<span className="font-semibold">Edit</span>
				</button>
			</div>
			<nav>
				<ul className="flex flex-col gap-4">
					<li>
						<NavLink
							to="website/new"
							className="p-4 flex items-center gap-2 rounded-2xl bg-[#F5F5FF] outline-0 hover:bg-[#EBEBFF] focus:ring-4 focus:ring-opacity-50 focus:ring-[#FF73E1]"
						>
							<IconPlus />
							<span className="font-semibold">Create website</span>
						</NavLink>
					</li>
					{website_list.map((website) => (
						<li key={website.id}>
							<NavLink
								to={`website/${website.id}`}
								className="p-4 flex flex-col gap-2 rounded-2xl bg-[#F5F5FF] outline-0 hover:bg-[#EBEBFF] focus:ring-4 focus:ring-opacity-50 focus:ring-[#FF73E1]"
							>
								<div className="flex items-center gap-1">
									<img
										src={`${website.origin}/favicon.ico`}
										srcSet={`${website.origin}/favicon.png, ${website.origin}/favicon-16x16.png 1x, ${website.origin}/favicon-32x32.png 2x, ${website.origin}/apple-touch-icon.png 2x`}
										alt="icon"
										width={16}
										height={16}
									/>
									<span className="font-semibold">{website.name}</span>
								</div>
								<div className="flex items-center gap-1">
									<IconCompass size={16} />
									<span className="text-sm text-[#8D73FF]">{website.origin}</span>
								</div>
								<div className="flex items-center gap-1">
									<IconPin size={16} />
									{
										website.plan_id &&
										plans[website.plan_id] &&
										<>
											<span className="text-sm">{plans[website.plan_id].name}</span>
											<span className="text-sm text-[#B8B8B8]">({website.plan_id})</span>
										</>
									}
								</div>
							</NavLink>
						</li>
					))}
				</ul>
			</nav>
		</div>
	)
}
